import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { useState } from 'react';

import { iconTheme as ICON_THEME, sectionStyle } from 'holded/lib/styles';
import { cleanDoubleSlash } from 'holded/lib/utils';
import { NavbarItem } from 'holded/modules/cms/domain/page';
import HeroIcon from 'holded/modules/cms/ui/shared/components/HeroIcon';
import NextImage from 'holded/modules/cms/ui/shared/components/Image';
import { MenuState } from 'holded/shared/ui/components/Menu/Menu';
import MenuFooter from 'holded/shared/ui/components/Menu/MenuFooter';

export type MediaProps = {
  nav: NavbarItem;
  showMenu: boolean;
  handleMenuItem: (menu: MenuState) => void;
  desktop: boolean;
  dark: boolean;
};

const MenuMedia = ({ nav, showMenu, handleMenuItem, desktop, dark = true }: MediaProps) => {
  const [openState, setOpenState] = useState(false);

  const isDefaultDropdownAlignment = nav.attributes.mediaDropdownAlignment === 'default';

  const navItemOrientationClass = nav.attributes.orientation === 'left' ? 'right-0' : '';

  const navItemWidthClass = isDefaultDropdownAlignment ? 'xl:max-w-3xl' : 'xl:max-w-sm';
  const navListDropdownClass = isDefaultDropdownAlignment ? 'xl:flex-row' : 'xl:flex-col';
  const navListItemDropdownClass = isDefaultDropdownAlignment ? 'xl:w-1/2' : 'xl:w-full';

  const openMenu = () => {
    if (desktop) {
      setOpenState(true);
      handleMenuItem({ name: nav.attributes.name, state: true });
    }
  };

  const toggleMenu = () => {
    handleMenuItem({ name: nav.attributes.name, state: !openState });
    setOpenState(!openState);
  };

  const closeMenu = () => {
    if (desktop) {
      setOpenState(false);
      handleMenuItem({ name: nav.attributes.name, state: false });
    }
  };

  const hasMenuFooter = !!nav.attributes?.footer?.title;
  const textColorClosed = dark ? 'text-white' : sectionStyle.textColor.default;
  const textColorOpen = dark ? 'text-gray-strong' : 'text-gray-500';

  return (
    <div className="relative" key={nav.id}>
      <div key={nav.id} className="cursor-pointer" onMouseEnter={openMenu} onClick={toggleMenu}>
        <div className="focus:outline-none">
          <div className="p-5 group rounded-md inline-flex items-center text-base font-normal focus:outline-none xl:p-0">
            <span className={`${openState && showMenu ? `${textColorOpen}` : `${textColorClosed}`}`}>
              {nav.attributes.name}
            </span>
            {!openState || !showMenu ? (
              <ChevronDownIcon className={`ml-1 h-5 w-5 ${dark ? 'text-gray' : 'text-blue'}`} aria-hidden="true" />
            ) : (
              <ChevronUpIcon className={`ml-1 h-5 w-5 ${dark ? 'text-gray' : 'text-blue'}`} aria-hidden="true" />
            )}
          </div>

          {!openState && (
            <ul className="hidden">
              {nav.attributes.items.map((item) => (
                <li key={'hMenu' + item.id} className="hidden">
                  <a href={item.href.startsWith('http') ? item.href : `/${cleanDoubleSlash(item.href)}`}>
                    <p>{item.name}</p>
                    <p>{item.description}</p>
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>

        <div
          onMouseLeave={closeMenu}
          className={`focus:outline-none absolute z-20 mt-3 transform w-full xl:w-screen ${navItemWidthClass} ${navItemOrientationClass}`}
        >
          {openState && showMenu && (
            <ul
              className={`${
                dark ? 'bg-[#111926] border border-gray-800' : 'bg-white'
              } xl:rounded-lg xl:shadow-lg xl:ring-1 ring-black ring-opacity-5 overflow-hidden relative flex flex-col ${navListDropdownClass} xl:flex-wrap`}
            >
              {nav.attributes.items.map((item) => (
                <li key={item.id} className={`${navListItemDropdownClass} xl:p-2`}>
                  <a
                    href={item.href.startsWith('http') ? item.href : `/${cleanDoubleSlash(item.href)}`}
                    className={`pl-6 pr-2 py-2 flex items-center lg:items-start xl:rounded-lg ${
                      dark ? 'hover:bg-[#273243]' : 'hover:bg-gray-light'
                    } h-full`}
                  >
                    <div
                      className={`${
                        ICON_THEME[dark ? 'darkMode' : nav.attributes.iconTheme]
                      }  flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md sm:h-12 sm:w-12`}
                    >
                      {item.image ? (
                        <NextImage media={item.image} classImg="h-8 object-cover" />
                      ) : (
                        <HeroIcon icon={item.icon} outline={true} />
                      )}
                    </div>
                    <div className="ml-4">
                      <p className={`${textColorClosed} text-base font-medium`}>{item.name}</p>
                      {item.description && (
                        <p className={`hidden xl:block mt-1 text-sm ${sectionStyle.textColor.secondary}`}>
                          {item.description}
                        </p>
                      )}
                    </div>
                  </a>
                </li>
              ))}
              {hasMenuFooter && <MenuFooter nav={nav} dark={dark} />}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuMedia;
